import React, { Component } from 'react';
import Navbar from './Navbar';
import Footer from './footer';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const YOUTUBE_API = 'https://www.googleapis.com/youtube/v3/playlistItems';
require('dotenv').config();
export default class Sample extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
    };
  }
  componentDidMount() {
    fetch(
      `${YOUTUBE_API}?&part=snippet&playlistId=PLmZP1KIsC-hnj1U_Y-3fcx4sZ-540NrH8&maxResults=4&key=AIzaSyByttc-8oSHzUvNG_lAwSDhcD8HEZVmaFQ`
    )
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result.items,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }
  render() {
    return (
      <div>
        <Navbar />
        <section className="embed-section">
          <div className="container">
            <div className="row text-center">
              <div className="col-md-12">
                <h2 className="aboutheader">
                  Ramadan <nobr className="text-red">1446/2025</nobr> Lecture
                  Schedule
                </h2>
                <hr />
              </div>
            </div>
          </div>
        </section>
        <section className="embed-section">
          <div className="container">
            <table className="table table-bordered table-dark">
              <thead>
                <tr>
                  <td>TIMING</td>
                  <td>TOPIC</td>
                  <td>DAY</td>
                  <td>LOCATION</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>After Taraweeh 09:00 PM to 11:00 </td>
                  <td>Tafseer Surah Zumar to Juz 24</td>
                  <td>Everyday except Sunday</td>
                  <td>
                    <u>
                      <a
                        href="https://youtube.com/Zafarulhasan"
                        style={{ color: '#00FFFF' }}
                      >
                        <i
                          className="fa fa-map-marker text-success"
                          aria-hidden="true"
                        ></i>{' '}
                        Online
                      </a>
                    </u>
                  </td>
                </tr>
                <tr>
                  <td>Before Iftar 05:30 PM to 6:15 PM</td>
                  <td>Ramadan Aur Ihsan</td>
                  <td>Everyday </td>
                  <td>
                    <u>
                      <a
                        href="https://youtube.com/Zafarulhasan"
                        style={{ color: '#00FFFF' }}
                      >
                        <i
                          className="fa fa-map-marker text-success"
                          aria-hidden="true"
                        ></i>{' '}
                        Online
                      </a>
                    </u>
                  </td>
                </tr>
                <tr>
                  <td>After Asr 04:15 PM to 6:15 PM</td>
                  <td>Ramadan Ki Baharein</td>
                  <td>Saturday </td>
                  <td>
                    <u>
                      <a
                        href="https://maps.app.goo.gl/mscog2WwkqiHVszA8"
                        style={{ color: '#00FFFF' }}
                      >
                        <i
                          className="fa fa-map-marker text-success"
                          aria-hidden="true"
                        ></i>{' '}
                        Ibrahim Khaleel, Al Raffa
                      </a>
                    </u>
                  </td>
                </tr>

                <tr>
                  <td>After Asr 04:15 PM to 6:15 PM </td>
                  <td>Ramadan Ki Baharein</td>
                  <td>Sunday </td>
                  <td>
                    <u>
                      <a
                        href="https://maps.app.goo.gl/Lh6a1NzyvpTqze2o6"
                        style={{ color: '#00FFFF' }}
                      >
                        <i
                          className="fa fa-map-marker text-success"
                          aria-hidden="true"
                        ></i>{' '}
                        Masjid Khadijah bint Khuwailid, Al Quoz
                      </a>
                    </u>
                  </td>
                </tr>

                <tr>
                  <td>After Asr 04:15 PM to 6:15 PM</td>
                  <td>Ramadan Ki Baharein</td>
                  <td>Monday </td>
                  <td>
                    <u>
                      <a
                        href="https://maps.app.goo.gl/d2bN1JwhYV37hqBA9"
                        style={{ color: '#00FFFF' }}
                      >
                        <i
                          className="fa fa-map-marker text-success"
                          aria-hidden="true"
                        ></i>{' '}
                        Masjid Zarooni Al Kabeer, Al Nahda - 2
                      </a>
                    </u>
                  </td>
                </tr>
                <tr>
                  <td>After Asr 04:15 PM to 6:15 PM </td>
                  <td>Ramadan Ki Baharein</td>
                  <td>Tuesday </td>
                  <td>
                    <u>
                      <a
                        href="https://maps.app.goo.gl/kMoLGXqyhPBDpsxJ7"
                        style={{ color: '#00FFFF' }}
                      >
                        <i
                          className="fa fa-map-marker text-success"
                          aria-hidden="true"
                        ></i>{' '}
                        Masjid Yusuf Baqar, Hor Al Anz
                      </a>
                    </u>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-center about">
            Add Schedule to your calendar&nbsp;&nbsp;
            <a
              href="https://accounts.google.com/ServiceLogin?service=cl&passive=1209600&osid=1&continue=https://calendar.google.com/calendar/u/0/r/month?tab%3Dwc%26pli%3D1&followup=https://calendar.google.com/calendar/u/0/r/month?tab%3Dwc%26pli%3D1&scc=1"
              role="button"
              target="_blank"
              rel="noopener noreferrer"
              className="btn-lg btn-outline-dark Social-link-btn"
              style={{ backgroundColor: 'beige', color: '#5c4033' }}
            >
              <i className="fa fa-calendar" aria-hidden="true"></i> Calendar
            </a>
          </div>
        </section>

        <div className="text-center">
          <Footer />
        </div>
      </div>
    );
  }
}
