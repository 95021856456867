import React, { Component } from 'react';

export default class Footer extends Component {
  render() {
    return (
      <div>
        <footer class="text-white padding">
          <div class="container-fluid justify-content-center">
            <div class="row">
              <div class="col-md-12 text-center">
                <hr />
                <h5>فضيلة الشيخ ظفر الحسن مدني حفظه اللّه &copy;2025 </h5>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}
